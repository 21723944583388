.pageHeader{
    height: 8vh;
    font-size: large;
}

.projectTitle {
    font-size: large;
    text-align: left;
    padding: 1% 0 1% 10%;
    text-decoration: underline;
    height: 4vh;
}

.limitedHr {
    width: 80%;
}

.projectInlineStructure {
    display: inline-block;
    width: 40%;
    padding: 1vw;
    vertical-align: top;
}

.tightProjectInlineStructure {
    display: inline-block;
    width: 24%;
    padding: 0.5vw;
    vertical-align: top;
}

.smallProjectFigure {
    width: 90%;
    padding: 5%;
}

.overlayMask {
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: black;
    position: fixed;
    z-index: 1;
}

.overlayFigure {
    width: 60%;
    top: 15%;
    left: 20%;
    position: fixed;
    z-index: 2;
}

.aspectClass{
    aspect-ratio: 7/5;
}

.aspectClass img {
    width: 100%;
    height: 100%;
}
/*snow*/
.snowText {
    padding-left: 8%;
    padding-right: 8%;
    text-align: left;

}

/* mask */
.mask {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    opacity: 0;
    background-color: rgb(35, 35, 35);
    top: 0px; 
    left: 0px; 
}

.opaque {
    opacity: 0.9;
    z-index: 1;
}

.overlayMask {
    z-index: 2;
    position: fixed;
    height: auto !important;
    width: 40% !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
}

@media only screen and (max-width: 600px) {
    .projectInlineStructure {
        display: block;
        position: relative;
        width: 90%;
        left: 5%;        
    }

    .tightProjectInlineStructure {
        display: block;
        width: 95%;
        left: 2.5%;
    }

    .overlayMask {
        width: 90% !important;
    }
}