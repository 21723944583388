@import url('https://fonts.googleapis.com/css?family=Montserrat');


body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(42, 40, 52);
  color: white;
  text-align: center;
  font-size: 14.5px;
  background-image: repeating-linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.03) 1px,
    transparent 5px
  );
  background-size: 100% 8px; 
}

a:link {
  color:white
}

a:visited {
  color:white
}

a:hover {
  color: rgb(190,190,190)
}

html, body {
  overflow-y: scroll;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  width: 0;
  height: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
