/* title */
.titleContainer {
  position: relative;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; 
  height: 12vw;
}

.title {
  text-align: left;
  font-size: 3vw;
  width: auto;
  display: block;
  text-decoration: none;
  padding-left: 7%;
  bottom: -6vw;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 5vw;
  }
}

.tagLine {
  text-align: left;
  font-size: 1.2vw;
  width: auto;
  display: block;
  font-style: italic;
  text-decoration: none;
  padding-left: 8%;
  bottom: -6vw;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .tagLine {
    font-size: 2vw;
  }
}

/* home page */
.searchLinksLeft {
  padding-left: 7%;
  text-align: left;
  display: inline-block;
  font-size: 2vw;
  left: 0%;
  position: fixed;
}

.searchLinksRight {
  padding-right: 7%;
  text-align: right;
  display: inline-block;
  font-size: 2vw;
  right: 0%;
  position: fixed;
}

.asciiArt {
  position: absolute;
  bottom: 0%;
  left: 33%;
}


.bigAsciiArt {
  font-size: 3px;
  white-space: pre-line;
  position: absolute;
  bottom: 0%;
  left: 33%;
}

@media only screen and (max-width: 600px) {
  .searchLinksLeft {
    font-size: 4vw;
    padding-top: 5vw;

  }
  
  .searchLinksRight {
    font-size: 4vw;
    padding-top: 5vw;

  }
}

/* me page */

.textHolder {
  padding: 1vw;
  padding-left: 7%;
  padding-right: 7%;
  text-align: left;
}

@media only screen and (max-width: 400px) {
  .linkHolder {
    padding-top: 10vw;
  }
}

/* cv page */

.pdf-viewer {
  width: 70%;
  height: 50vw;
}

.downloadImage {
  width: 10px
}

.tooSmallCV {
  display: none;
}

.safariCV {
  display: none;
}

@media only screen and (max-width: 500px) {
  .pdf-viewer {
    display: none;
  }
  .tooSmallCV {
    display: block;
  }
}

@media (max-aspect-ratio: 3/2) {
  .pdf-viewer {
    height: 100vw;
  }
}

/* Safari 7.1+ */

_::-webkit-full-page-media, _:future, :root .safariCV {
  display: block;
}
_::-webkit-full-page-media, _:future, :root .pdf-viewer {
  display: none;
}

/* diss page */

.dissSubtitle {
  font-size: 18px;
}

.inlineButton {
  padding: 1vw;
  display: inline-block;
  vertical-align: top;
}

.dissInlineStructure {
  padding: 1vw;
  display: inline-block;
  width: 40%;
  vertical-align: top;
}

@media only screen and (max-width: 1100px) {
  .dissSubtitle {
    padding-top: 10vw;
  }
}

@media only screen and (max-width: 600px) {
  .dissInlineStructure {
    display: block;
    width: 90%;
    left: 5%;
    position: sticky;
    
  }
}

/* link page */

.linkTitle {
  font-size: x-large;
}

.linksContainer {
  text-align: left;
  padding: 8vh 10%;
  font-size: larger;
}

.link{
  padding: 4vh 0%;
}
.link a  {
  color:rgb(254, 75, 120);
}
.link:visited {
  color:rgb(211, 3, 103);
}
.link:hover {
  color: rgb(290, 14, 61);
  text-decoration: underline;
}

/* reused classes */

.leftAlign {
  text-align: left;
}

.underlined{
  text-decoration: underline;
}

.smallLink:link  {
  color:rgb(254, 75, 120) !important;
}
.smallLink:visited {
  color:rgb(211, 3, 103) !important;
}
.smallLink:hover {
  color: rgb(290, 14, 61) !important;
  text-decoration: underline;
}

.backButton {
  left: 4%;
  position: absolute;
  display: inline-block;
}

.smallBackButton {
  left: 4%;
  position: absolute;
  display: none;
}

@media only screen and (max-width: 420px) {
  .backButton {
    display: none;
  }

  .smallBackButton {
    display: inline-block;
    padding: 5px 10px;
  }
}

.subTitle{
  font-size: 17px;
}

.subSubTitle{
  font-weight: bold;
}

.fruitLipsContainer{
  position: fixed;
  top: 15%;
  height: 80%;
  width: 100%;
}

.squaresContainer {
  position: fixed;
  top: 11%;
  height: 90%;
  width: 100%;
}

/* General css writes */

img {
  height: 100%;
  width: 100%;    
}

hr {
  color: rgb(73, 67, 105);
  border: none;
  height: 1.5px;
  background-color: rgb(73, 67, 105);
}

a {
  text-decoration: none;
}

pre {
  white-space: pre-wrap;
}

button {
  border-radius: 2px;
  font-family: 'Montserrat', sans-serif;
  appearance: none; 
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  margin: 15px 0;
  padding: 10px 20px;
  background: #eee;
  letter-spacing: 0.2em;
  line-height: 100%;
  border-radius: 3px;
  opacity: 0.9;
  transition: opacity 0.3s ease;
}

button:hover {
  opacity: 1;
}

button:active {
  transform: scale(0.98); 
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24); 
}

input {
  border-radius: 2px;
  font-family: 'Montserrat', sans-serif;
  appearance: none; 
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  margin: 15px 0;
  padding: 10px 0px;
  letter-spacing: 0.2em;
  line-height: 100%;
  border-radius: 3px;
  opacity: 0.9;
  transition: opacity 0.3s ease;
  width: 20vw;
}

* {
  caret-color: transparent;
}