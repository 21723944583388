.chart {
    width: 80%;
    left: 10%;
    position: relative;
}

.tooSmallCharts {
    display: none;
    padding: 3vw;
    padding-top: 10vw;
}

@media (max-width: 800px) {
    .chartIntro {
        padding-top: 5vw;
    }
}

/* If page size gets too small remove the charts */

@media (max-width: 650px) {

    .tooSmallCharts {
        display: block;
    }

    .chartContainer {
        display: none;
    }
}
