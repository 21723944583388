
.dissContainer {
    height: 48vw;
    width: 100%;
    padding-bottom: 10px;
}

.imagesContainer {
    height: 48vw;
    width: 100%;
}

.imageChoice {
    width: 16vw;
    height: 16vw;
    display: inline-block;
    padding: 6px;
    font-size: 12px;
}



.imageChoiceImage {
    width: 90%;
    height: 90%;
    padding: 5%;

}

.imageChoiceImage:hover{
    background: -webkit-linear-gradient(0deg, rgb(211, 3, 103) 50%, transparent 50%), -webkit-linear-gradient(0deg, rgb(211, 3, 103) 50%, transparent 50%),
     -webkit-linear-gradient(90deg, rgb(211, 3, 103) 50%, transparent 50%), -webkit-linear-gradient(90deg, rgb(211, 3, 103) 50%, transparent 50%);
    background: -moz-linear-gradient(90deg, rgb(211, 3, 103) 50%, transparent 50%), -moz-linear-gradient(90deg, rgb(211, 3, 103) 50%, transparent 50%),
     -moz-linear-gradient(0deg, rgb(211, 3, 103) 50%, transparent 50%), -moz-linear-gradient(0deg, rgb(211, 3, 103) 50%, transparent 50%);
    background: linear-gradient(90deg, rgb(211, 3, 103) 50%, transparent 50%), linear-gradient(90deg, rgb(211, 3, 103) 50%, transparent 50%),
     linear-gradient(0deg, rgb(211, 3, 103) 50%, transparent 50%), linear-gradient(0deg, rgb(211, 3, 103) 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-position:  0% 0%, 100% 100%, 0% 100%, 100% 0%; 
    background-size: 16px 4px, 16px 4px, 4px 16px, 4px 16px;
}

.uploadedPanel {
    display: none;
}


.inlineInput {
    height: 15vw;
    display: inline-block;
    width: 15vw;
    padding: 4%;
    font-size: 12px;
  }

.inputImage {
    width: 100%;
    height: 100%;

}

.inputBrowse {
  padding: 2px;
  margin: 0%;
  border-radius: 0%;
  font-size: 11px;

}

.submitButton {
    position: relative;
    margin: 5px 0;
    padding: 5px 15px;
    margin-top: 12px;
    font-size: 11px;
}

.smallText {
  font-size: 10px;
  font-style: italic;
}

.tick {
    width: 1.5vw;
    height: 1.5vw;
    bottom: -0.5vw;
    left: 1.6vw;
    position: relative;
    display: none;
}

.inline {
    display: inline-block;
}

.dissToolText {
    left: 27.5%;
    position: relative;
    text-align: left;
    width: 45%;
}

.loader {
    border: 4px solid rgb(73, 67, 105);;
    border-top: 4px solid rgb(211, 3, 103); 
    border-radius: 50%;
    width: 1vw;
    height: 1vw;
    animation: spin 2s linear infinite;
    bottom: -0.5vw;
    left: 1.6vw;
    position: relative;
    display: none;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

    
  .dissTooSmall {
    display: none;
  }


  @media only screen and (max-width: 900px) {
    .dissContainer {
      display: none;
    }
  
    .dissTooSmall {
      display: none; /* change back to block */
      padding: 20px;
    }
  }

  .errorMsg {
    display: none;
  }

  .notFinished {
    display: none;
  }